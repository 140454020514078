<script lang="ts" setup>
  interface IProps {
    name?: string;
    rating?: string;
    size?: 'normal' | 'small';
  }
  const props = withDefaults(defineProps<IProps>(), {
    size: 'normal',
  });
  const iconName = computed(() => `icon-${props.name}`);
</script>

<template>
  <div class="movie-rating" :class="[`movie-rating--${props.name}`]" :data-size="props.size">
    <component :is="iconName" v-if="iconName"></component>
    {{ props.rating }}
  </div>
</template>

<style lang="scss" scoped>
  .movie-rating {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 0 11px;
    height: 36px;
    color: $main_white;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.16);
    &--flex {
      background: radial-gradient(
          50.11% 34.33% at 50.22% 91.67%,
          rgba(255, 255, 255, 0.3) 3.13%,
          rgba(255, 255, 255, 0.15) 41.15%,
          rgba(0, 0, 0, 0.15) 100%
        ),
        #5f00d7;
      border-bottom: 1px solid rgba(255, 255, 255, 0.9);
      svg {
        display: none;
      }
    }
    &[data-size='small'] {
      font-size: 12px;
      svg {
        height: 12px;
      }
    }
    @media (max-width: $retina) {
      font-size: 12px;
      font-weight: 400;
      height: 28px;
      border-radius: 8px;
      gap: 4px;
      svg {
        height: 12px;
      }
      &--flex {
        //padding: 8px 8px 7px;
        svg {
          display: block;
        }
      }
    }
  }
</style>
